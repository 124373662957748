<!-- 新闻资讯 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top">
        <top-nav></top-nav>
      </div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
    </div>
    <div style="background-color: #f5f5f5;">
    <div class="content_c">
      <el-tabs :tab-position="tabPosition" v-model="defaultName" @tab-click="handleClick">
        <el-tab-pane :disabled="true">
          <span slot="label"><img style="
    width: 30px;
    height: 6px;" src="../../assets/center/left.png" alt=""> 新闻资讯 <img style="
    width: 30px;
    height: 6px;" src="../../assets/center/right.png" alt=""></span>
        </el-tab-pane>
        <el-tab-pane :label="item.typename" v-for="(item,index) in leftList" :key="index">
          <div class="crumbs">
            <div class="crumbs_img"><img src="../../assets/home.png" alt=""></div>
            <div class="crumbs_text">
              您当前得位置:</div>
            <div class="crumbs_content">
              <span>首页 ></span>
              <span>新闻资讯 ></span>
              <span>{{item.typename}}</span>
            </div>
          </div>
          <div class="centent_center">
            
      <div class="content_list"  v-for="(item, index) in newsList" :key="index"
        @click="goDetails(item.pid)">
        <div class="img" v-if="item.pathUpload">
          <img :src="item.pathUpload" alt="" />
        </div>
        <div class="list_content">
          <div class="title">{{ item.title }}</div>
          <div class="text">
            <div class="content">{{item.other}}</div>
          </div>
          <div class="time">时间{{ item.submitTime }}</div>
        </div>
        <!-- <div class="details" ></div> -->
      </div>
      <el-empty v-if="newsList.length<=0" description="暂无数据"></el-empty>
      <div class="pagination" v-else>
        <el-button size="small" @click="pervious">上一页</el-button>
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage1" background
          :page-size="pageSize" layout="pager" :total="total" ref="pageGroup">
        </el-pagination>
        <el-button size="small" @click="next">下一页</el-button>
      </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import { listPain, menuList } from "../../api/api";
import topNav from "../../components/topNav";
import bottom from "../../components/bottom";
import { province } from "../../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      userInfo: "",
      newsList: [],
      partnerId: undefined,
      currentPage1: 1,
      total: 0,
      pageSize: 5,
      // 二级菜单
      tabPosition: "left",
      leftList: [],
      detailsItem: {},
      defaultName: '1',
      pid:''
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        // console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    pervious() {
      this.$refs.pageGroup.prev();
    },
    next() {
      this.$refs.pageGroup.next();
    },
    goDetails(e) {

      this.$router.push({
        path: '/listDetails',
        query: {
          pid: e,
          type: 1,
          isTitle: 1
        },
      });
    },
    async init() {
      let res = await menuList();
      if (res.ResultCode == 0) {
        console.log(res,'获取菜单');
        this.leftList = res.Data[2].childs;
        this.pid=this.leftList[0].pid
        this._intoNews()
        // this.getDate(this.leftList[0].pid);
        // this.getDateProject(res.Data[3].childs[0].pid);
      }
    },
    // 切换
    async handleClick(tab) {
      console.log(tab.index, this.leftList[tab.index]);
      
      this.pid=this.leftList[tab.index-1].pid
      // this.getDate(this.leftList[tab.index-1].pid);
        this._intoNews()
    },
    //获取文章列表
    async _intoNews() {
      // console.log(this.$route.query.pid,'this.$route.query.pid',this.$router.path);
      let pid = this.pid
      let res = await listPain({
        typeidSelsource: pid,
        page: this.currentPage1,
        limit: this.pageSize,
      });
      if (res.ResultCode == 0) {
        console.log(res.Data, "res909090");
        this.newsList = res.Data.Items;
        this.total = res.Data.TotalRecordCount
      }
    },
    async handleCurrentChange(val) {
      this.currentPage1 = val
      this._intoNews()
    },
  },
};
</script>

<style lang="scss" scoped>
  .centent_center{
    background-color: #ffffff;
    width: 100%;
    padding: 5px 20px;
    margin-bottom: 50px;
  }
  
 // 面包屑
 .crumbs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #666666;
    font-size: 14px;
    font-weight: 500;
  
    height: 50px;
    &_img {
      width: 18px;
      height: 18px;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &_text {
      padding-left: 10px;
    }
  
    &_content {
      color: #666666;
      font-size: 14px;
    }
  }
.box {
  width: 100%;
  box-sizing: border-box;

  .nav_top {
    width: 100%;
    height: 50vh;
    background-image: url("../../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';
      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }

    .top {
      width: 100%;
      height: 6.25rem;
      background-size: 100% 6.25rem;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 4.125rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 8.75rem;
    }

    h2 {
      font-size: 2.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 3.1875rem;
    }
  }

  .content_c {
    width: 87.5rem;
    margin: 0 auto;
    padding-top: 4.5rem;
    .content_list:hover .list_content .title {
      color: #2477E4;
    }

    .content_list:hover {
      transform: translateX(20px);
      transition: all 0.5s;
    }

    .content_list {
      cursor: pointer;
      width: 100%;
      height: 11.5rem;
      margin: 0 auto;
      margin-top: 1.975rem;
      display: flex;
      justify-content: flex-start;
      position: relative;

      .img {
        width: 21.375rem;
        height: 11.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .list_content {
        width: 100%;
        padding: 1.25rem;
        height: 11.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: .0625rem solid #eee;

        .title {
          font-size: 1.25rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }

        .text {
          height: 6.25rem;

          .content {
            font-size: .875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            // 两行显示
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .time {
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 1.9375rem;
        }
      }

      .details {
        width: 3.75rem;
        height: 1.875rem;
        position: absolute;
        bottom: .1875rem;
        right: 0;
        background-image: url("../../assets/newList/details.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .details:hover {
        background-image: url("../../assets/newList/details_select.png");
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 1.25rem auto;
    }
  }

  .content5 {
    width: 100%;
    height: 19.375rem;
    background: #f5f5f5;
  }
}

::v-deep {
  .rich>>>img {
    width: 100% !important;
  }

  .rich>>>table tr td {
    border: none !important;

  }

 
  .el-tabs__content {
    // background-color: #fff;
  }

  .el-tabs__nav.is-left {
    width: 200px;
    background-color: #fff;
  }

  .el-tabs__item {
    height: 60px;
    line-height: 60px;
  }

  .el-tabs--left .el-tabs__item.is-left {
    text-align: center;
    font-size: 18px;
    // font-family: AlibabaPuHuiTi-2-55-Regular;

    span {
      font-size: 20px;
    }


  }
  .el-tabs__item:hover {
    background-color: rgba(36, 119, 228, .1);
  }

  .el-tabs__item.is-active {
    background-color: rgba(36, 119, 228, .1);

  }

  #tab-0 {
    background-color: #356CEB;
    color: #fff;

  }

  .el-tabs__active-bar.is-right {}

  .el-tab-pane {
    margin: 0 100px;
    margin-right: 0;
  }
  .pagination{
    height: 1.875rem !important;
    line-height: 1.875rem !important;
  }
}
.emypt{
  text-align: center;
  height: 200px;
  line-height: 200px;
}
</style>
